import type { Session } from "@supabase/supabase-js";
import { onlineManager } from "@tanstack/react-query";
import { clsx, type ClassValue } from "clsx";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getUserDataFromSession(session: Session) {
  const userId = session.user.id;
  const userEmail = session.user.email;
  const userRole = session.user.role;

  return { userId, userEmail, userRole };
}

export function calculateCommission(volume: number, pricePerLiter: number) {
  let commission_percentage: number;

  if (volume <= 30000) {
    commission_percentage = 4.0;
  } else if (volume > 30000 && volume <= 250000) {
    commission_percentage = 3.5;
  } else if (volume > 250000 && volume <= 500000) {
    commission_percentage = 3.0;
  } else if (volume > 500000 && volume <= 1000000) {
    commission_percentage = 2.5;
  } else {
    commission_percentage = 2.0;
  }

  const total = volume * pricePerLiter;
  const commission = (commission_percentage / 100) * total;

  const formattedTotal = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "ZAR",
  }).format(total);

  const formattedCommission = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "ZAR",
  }).format(commission);

  const effectivePrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "ZAR",
  }).format((total - commission) / volume || 0);

  const commissionPerLiter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "ZAR",
  }).format(commission / volume || 0);

  return {
    total: formattedTotal,
    commission: formattedCommission,
    commission_percentage,
    effectivePrice,
    commissionPerLiter,
  };
}

export function formatToTwitterDate(dateTimeString: string) {
  const date = new Date(dateTimeString);

  const day = date.getDate();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();

  // Convert hours to AM/PM format
  const amPM = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert hours to 12-hour format

  const formattedDate = `${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${amPM} · ${month} ${day}, ${year}`;

  return formattedDate;
}

export function authenticateToken(token: string) {
  const serverToken = process.env.POSTMARK_SERVER_TOKEN!;

  if (token === serverToken) {
    return true;
  } else {
    return false;
  }
}

export function useOnlineStatus() {
  const [isOnline, setIsOnline] = useState(onlineManager.isOnline());

  useEffect(() => {
    return onlineManager.subscribe(setIsOnline);
  }, []);

  return isOnline;
}

export function sanitizeNumber(number: string) {
  //Number comes in as 083 554 3133
  //Needs to go out as +27835543133

  const sanitizedNumber = number.replace(/\s/g, "");

  //Check if number starts with 0
  if (sanitizedNumber.startsWith("0")) {
    const sanitizedNumberWithCountryCode = `+27${sanitizedNumber.slice(1)}`;
    return sanitizedNumberWithCountryCode;
  } else {
    return sanitizedNumber;
  }
}
